import {Dropdown, Icon, Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import * as _ from "lodash";
import {connect} from "react-redux";
import {closeMenuDrawer, openMenuDrawer} from "../../redux/actions/utilityActions";
import {Trans} from "react-i18next";
import i18n from "../../../i18n";
import {loadAndChangeLanguage} from "../../utils/translations";

const SubMenu = Menu.SubMenu;

class AppHeaderMenu extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    updateLanguage = (lang) => {
        let that = this;
        let succsss = function (){
            that.props.setMyLanguage({
                type: 'INTERNATIONAL_LANGUAGE',
                        payload: lang,
            })
        }
        loadAndChangeLanguage(lang,succsss);

    }


    render() {
        let diseaseTypeArray = Object.keys(this.props.baseData.diseaseCat);
        let that = this;

        return <Menu
            onSelect={this.props.closeMenuDrawer}
            className="appHeader"
            // theme="#05c660"
            mode={this.props.mode || "horizontal"}
            defaultSelectedKeys={['1']}
        >
            <Menu.Item key="1"><Link to="/"><Trans i18nKey="Header_Head1"/></Link></Menu.Item>
            <Menu.Item key="2"><Link to="/aboutus"><Trans i18nKey="Header_Head2"/></Link></Menu.Item>
            <Menu.Item key="3"><Link to="/blog"><Trans i18nKey="Header_Head3"/></Link></Menu.Item>
            <Menu.Item key="9"><Link to="/product"><Trans i18nKey="Header_Head9"/></Link></Menu.Item>
            <Menu.Item key="6"><Link to="/video"><Trans i18nKey="Header_Head5"/></Link></Menu.Item>
            <Menu.Item key="7"><Link to="/event"><Trans i18nKey="Header_Head6"/></Link></Menu.Item>
            <Menu.Item key="8"><Link to="/contact"><Trans i18nKey="Header_Head8"/></Link></Menu.Item>
            <Menu.SubMenu title={this.props.lang||"ENGLISH"} key={"16"}>
                <Menu.Item key={"ENGLISH"} onClick={(value) => that.updateLanguage("ENGLISH")}>
                    <span > ENGLISH</span>
                </Menu.Item>
                <Menu.Item key={"HINDI"} onClick={(value) => that.updateLanguage("HINDI")}>
                    <span > HINDI</span>
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    }
}

const mapStateToProps = state => {
    return {
        lang: _.get(state, 'global.intlLang'),
    }
};

const mapDispatchToProps = dispatch => ({
    closeMenuDrawer,
    openMenuDrawer,
    setMyLanguage: action => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeaderMenu)
