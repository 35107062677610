import {CLOSE_MENU_DRAWER, OPEN_MENU_DRAWER} from "../reduxConstant";

export const openMenuDrawer = (payload) => (dispatch, getState, {api}) => {
    return dispatch({
        type: OPEN_MENU_DRAWER,
        payload: payload
    });
}
export const closeMenuDrawer = (payload) => (dispatch, getState, {api}) => {
    return dispatch({
        type: CLOSE_MENU_DRAWER,
        payload: payload
    });
}
