import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from "lodash/get"
import {List, Avatar, Row, Col, Card} from "antd";

class CartList extends Component {
    render() {
        let {cartList} = this.props
        return (

            <Row gutter={[32, 32]} className="section mt-2 mb-2 main-container">
                <Card>
                    <Col span={12}>
                        <List
                            itemLayout="horizontal"
                            dataSource={cartList}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar
                                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>}
                                        title={<a href="https://ant.design">{item.name}</a>}
                                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                            )}
                        />
                    </Col>
                </Card>
                <Card>
                    <Col span={12}>
                        <h2>checkout</h2>
                    </Col>
                </Card>
            </Row>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        cartList: _get(state, 'shop.cart')
    };
}

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(CartList);
