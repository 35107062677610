import React from "react";
import {Col, Divider, List, Row, Icon, Avatar} from "antd";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

const data2 = [
    {
        title: <Trans i18nKey="Footer_Tags2_Head1"/>,
    },
    {
        title:  <Trans i18nKey="Footer_Tags2_Head2"/>,
    },
    {
        title:  <Trans i18nKey="Footer_Tags2_Head3"/>,
    },
    {
        title:  <Trans i18nKey="Footer_Tags2_Head4"/>,
    },
];

const data1 = [
    {
        title: <Link to={"/blog"}><Trans i18nKey="Header_Head3"/></Link>,
    },
    {
        title: <Link to={"/video"}><Trans i18nKey="Header_Head5"/></Link>,
    },
    {
        title: <Link to={"/event"}><Trans i18nKey="Header_Head6"/></Link>,
    },
    {
        title: <Link to={"/contact"}><Trans i18nKey="Header_Head8"/></Link>,
    },
    {
        title: <Link to={"/product"}><Trans i18nKey="Header_Head9"/></Link>,
    },
];

export default class AppFooter extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
     }

    render() {
        return (<Row>
            <Col span={24}>
                <div className="appFooter">
                    <div className="main-container">
                        <Row gutter={24}>

                            <Col xs={24} sm={12} md={6} lg={6} xl={6}><Divider><h2><Trans i18nKey="Footer_head2"/></h2></Divider><TagList1/></Col>
                            {/*<Col xs={24} sm={12} md={6} lg={6} xl={6}><Divider><h2><Trans i18nKey="Footer_head3"/></h2></Divider><TagList2/></Col>*/}
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}><Divider><h2><Trans i18nKey="Footer_head4"/></h2></Divider>
                                <p><Icon type="phone"/>&nbsp;+(91) 9019700600</p>
                                <p><Icon type="mail"/>&nbsp; care@ayushkalpayurveda.com</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col span={24}>
                <div className="copyrightFooter">
                    <p>Copyright 2018 | Ayushkalp Ayurveda</p>
                </div>
            </Col>
        </Row>)
    }
}
// export default withRouter(AppFooter)


function TagList2() {
    return <List
        size="small"
        itemLayout="horizontal"
        dataSource={data2}
        renderItem={item => (
            <List.Item>
                <List.Item.Meta
                    title={item.title}
                />
            </List.Item>)}
    />

}

function TagList1() {
    return <List
        size="small"
        itemLayout="horizontal"
        dataSource={data1}
        renderItem={item => (
            <List.Item>
                <List.Item.Meta
                    title={<a>{item.title}</a>}
                />
            </List.Item>)}
    />

}

function Diseases(props) {
    if (props.disease.length)
        return <List
            itemLayout="horizontal"
            dataSource={props.disease.slice(0,5)}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<Link to={"/disease/"+item.domain}>{item.disease_name}</Link>}
                    />
                </List.Item>)}
        />
    return null;
}
