import {combineReducers} from "redux";
import utilityReducer from "./utilityReducer";
import globalReducer from "./globalReducer";
import shopReducer from "./shopReducer";



//import all reducer
export const rootReducer = combineReducers({
    global: globalReducer,
    utility: utilityReducer,
    shop:shopReducer
});




