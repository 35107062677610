/***
 *
 * Cart Reducers Constants
 */
export const ADD_PRODUCT_TO_CART = 'addProductToCart';
export const CHANGE_PRODUCT_QUANTITY_TO_CART = 'changeProductQuantityToCart';
export const REMOVE_PRODUCT_FROM_CART = 'removeProductFromCart';
export const REMOVE_ALL_PRODUCT_FROM_CART = 'removeAllProductFromCart';
export const OPEN_MENU_DRAWER = 'openMenuDrawer';
export const CLOSE_MENU_DRAWER = 'closeMenuDrawer';

