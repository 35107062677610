import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './app/assets/theme.less';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {Spin} from "antd";
import App from "./App";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import configureStore from "./app/redux/store";
import _get from "lodash/get";

const persistantStore = configureStore();
const {store, persistor} = persistantStore;

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/*<I18nextProvider i18n={i18n}>*/}
                <Suspense
                    fallback={(
                        <Spin spinning tip="Loading...." size={"large"}>
                            <div style={{width: '100vw', height: '100vh'}}/>
                        </Spin>
                    )}>
                    <App/>
                </Suspense>
                {/*</I18nextProvider>*/}
            </PersistGate>
        </Provider>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
