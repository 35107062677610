import i18n from "i18next";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../constants/messages";
import {WEB_DYNAMIC_CONTENT} from "../constants/api";
import {displayMessage, getAPI, startLoadingMessage, stopLoadingMessage} from "./common";
import configureStore from "../redux/store";

const persistantStore = configureStore();
const {store, persistor} = persistantStore;

export const loadAndChangeLanguage = function (language,successFnCallback) {
    let msg = startLoadingMessage(`Loading ${language}...` );
    let successFn = function (data) {
        let translations = {};
        data.results.forEach(function (item) {
            translations[item.title] = item.body
        });
        i18n.removeResourceBundle(language, 'translation');
        i18n.addResourceBundle(language, 'translation', translations, true, true);
        i18n.changeLanguage(language);
        // store.dispatch({
        //     type: 'INTL_LANGUAGE_HASH_KEY',
        //     payload: Math.random(),
        // });
        if(successFnCallback)
            successFnCallback()
        stopLoadingMessage(msg,SUCCESS_MSG_TYPE,`${language} loaded successfully...`);
        // store.dispatch({
        //         type: 'INTERNATIONAL_LANGUAGE',
        //         payload: language,
        //     });
    }
    let errorFn = function () {
        stopLoadingMessage(msg,ERROR_MSG_TYPE,`${language} loading failed...`);
    }
    let apiParams = {
        language: language,
        category: "MAWEB",
        page_size: 1000
    }
    getAPI(WEB_DYNAMIC_CONTENT, successFn, errorFn, apiParams);
}