import * as _ from 'lodash';
import {
    ADD_PRODUCT_TO_CART,
    CHANGE_PRODUCT_QUANTITY_TO_CART, CLOSE_MENU_DRAWER, OPEN_MENU_DRAWER,
    REMOVE_ALL_PRODUCT_FROM_CART,
    REMOVE_PRODUCT_FROM_CART
} from "../reduxConstant";

export default (state = {}, action) => {
    // console.log("action", action)
    switch (action.type) {
        case OPEN_MENU_DRAWER :
            return {
                ...state,
                menuDrawerVisible: true
            }
        case CLOSE_MENU_DRAWER :
            return {
                ...state,
                menuDrawerVisible: false
            }
        default:
            return state;
    }
}
