export default (state = {}, action) => {
    switch (action.type) {
        case 'INTERNATIONAL_LANGUAGE' :
            return {
                ...state,
                intlLang: action.payload,
            }
        case 'INTL_LANGUAGE_HASH_KEY' :
            return {
                ...state,
                intlLangHashKey: action.payload,
            }
        default:
            return state;
    }
}
