import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationsEN from './locales/en/translations.json';
import translationsHI from './locales/hi/translations.json';
import _get from "lodash/get";
import configureStore from "./app/redux/store";
import {WEB_DYNAMIC_CONTENT} from "./app/constants/api";
import {displayMessage, getAPI} from "./app/utils/common";
import {ERROR_MSG_TYPE} from "./app/constants/messages";

const persistantStore = configureStore();
const {store, persistor} = persistantStore;
let storeState = store.getState();
let lang = _get(storeState, 'global.intlLang');

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            defaultEN: {translation: translationsEN},
            ENGLISH: {translation: translationsEN},
            HINDI: {translation: translationsHI}
        },
        fallbackLng: 'defaultEN',
        debug: process.env.NODE_ENV != 'production' || false,
        lng: lang,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });


export default i18n;
