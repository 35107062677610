import React from 'react';
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_URL = BACKEND_BASE_URL + '/erp-api';
export const EVENTS_LIST = 'events/';
export const EVENT_DETAIL_API = 'events/slug/?domain=%s';
export const CONTACTS_API = 'contactus/';
export const SLIDER_IMAGE_API = 'slider/';
export const BLOG_POSTS_API = 'post/';
export const BLOG_DETAIL_API = 'post/slug/?slug=%s';
export const VIDEO_LIST_API = 'video/';
export const PAGE_SEO_API = 'page_seo/';
export const WEB_DYNAMIC_CONTENT = 'dynamic-data/';
export const MANAGE_PRODUCT = 'product_content/';
export const IMAGE_BASE_URL = BACKEND_BASE_URL + '/media';
export const LANDING_VIDEOS_API = 'landing_page_video/';
export const LANDING_CONTENT_API = 'landing_page_content/';
export const BLOG_CONTACTUS_MAIL = 'contactus-mail/';
export const SUGGESTIONS = 'suggestions/';




export const RAZORPAY_KEY = 'rzp_live_Wl01N16ABswm3p';
export const PATIENTS_LIST = 'patients/';
export const DISEASE_LIST_API = 'arogyam_disease/';
export const DISEASE_DETAIL_API = 'arogyam_disease/slug/?domain=%s';
export const BLOG_RATING_API = 'arogyam_rating/create_with_ip/?id=%s';
export const BLOG_COMMENTS_API = 'arogyam_post/%s/comments/';
export const BLOG_MAKE_COMMENT_API = 'arogyam_comment/';
/**suggestions**/

export const CHECK_SEAT_AVAILABILITY = 'clinics/%s/seat_availability/';
export const ALL_CLINICS = 'clinics/';
export const BED_PACKAGES = 'clinics/%s/bed_packages/';
export const MEDICINE_PACKAGES = 'clinics/%s/medicine_packages/';
export const BOOK_SEAT = 'clinics/%s/seat_booking/';
export const DISEASE_LIST = 'clinics/%s/other_diseases/';
export const FILE_UPLOAD_API = 'blogImage/';
export const BOOKING_PRINT_PREVIEW = 'clinics/%s/booking_pdf/';
export const ACCEPT_PAYMENT = 'clinics/accept_payments/';
export const PRODUCTS='inventory'


