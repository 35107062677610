import React from "react";
import {Layout, Menu, Dropdown, Icon, Row, Col, Button, Drawer} from "antd";
import {connect} from "react-redux";
import * as _ from "lodash";
import AppHeaderMenu from "./AppHeaderMenu";
import {closeMenuDrawer, openMenuDrawer} from "../../redux/actions/utilityActions";
import {loadAndChangeLanguage} from "../../utils/translations";


const SubMenu = Menu.SubMenu;
const {Header} = Layout;

class AppHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuDrawerVisible:false
        }
    }

    render() {
        let that = this;
        let {cart,menuDrawerVisible,lang} = this.props;
        console.log(lang)
        return (
            <Header style={{
                background: '#fff',
                position: 'fixed',
                zIndex: 1,
                width: '100%',
                boxShadow: '0 5px 12px rgba(0,0,0,0.25)'
            }}>
                <div className="app-logo"/>
                <Row style={{
                    lineHeight: '62px',
                    maxWidth: 'calc(100% - 70px)',
                    float: 'right',
                    // marginLeft: '70px',
                    display: 'inline-block',
                }}>
                    <Col xs={0} sm={0} md={24} lg={24} style={{padding:9}}>
                        <AppHeaderMenu {...this.props} mode={"horizontal"} style={{display:'inline-block'}}/>
                    </Col>
                    <Col xs={24} sm={24} md={0} lg={0}>
                        <Button icon={"menu-fold"} size={"large"} onClick={this.props.openMenuDrawer}/>
                        <Drawer visible={menuDrawerVisible} onClose={this.props.closeMenuDrawer} title={"Menu"}>
                            <AppHeaderMenu {...this.props} mode={"inline"}/>
                        </Drawer>
                    </Col>
                </Row>

            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: _.get(state, 'global.intlLang'),
        cart: _.get(state, 'cart'),
        menuDrawerVisible:_.get(state,'utility.menuDrawerVisible')
    }
};

const mapDispatchToProps ={
    closeMenuDrawer,
    openMenuDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)

