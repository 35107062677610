import * as _ from 'lodash';
import {
    ADD_PRODUCT_TO_CART,
    CHANGE_PRODUCT_QUANTITY_TO_CART,
    REMOVE_ALL_PRODUCT_FROM_CART,
    REMOVE_PRODUCT_FROM_CART
} from "../reduxConstant";

export default (state = {}, action) => {
    console.log("action", action, state)
    switch (action.type) {
        case ADD_PRODUCT_TO_CART :
            if (state.cart) {
                return {
                    ...state,
                    cart: [...state.cart, action.payload],
                }
            } else {
                return {
                    ...state,
                    cart: [action.payload],
                }
            }


        default:
            return state;
    }
}
