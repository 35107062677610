import React from "react";
import {Avatar, Card, Col, Divider, List, Row, Icon, Button, Spin} from "antd";
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from "react-i18next";
import _get from "lodash/get";
import {connect} from "react-redux";
import {Carousel} from 'react-responsive-carousel';
import UndrawDoctors from 'react-undraw/dist/illustrations/UndrawDoctors';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {UNDRAW_PRIMARY_COLOR} from "../../app.config";
import {BLOG_DEFAULT_THUMB} from "../constants/dataKeys";
import {getAPI, stripHTML, makeFileURL} from "../utils/common";
import {BLOG_POSTS_API, SLIDER_IMAGE_API} from "../constants/api";

class Home extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            videoURL: 'http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4',
            carouselImages: [],
            blogPosts: [],
            loading: false,
            loadingBlog: false,
        }
    }

    componentDidMount() {
        this.loadCarouselImages();
        this.loadBlogPosts()
    }

    loadCarouselImages() {
        let that = this;
        this.setState({
            loading: true,
        });
        let successFn = function (data) {
            that.setState({
                carouselImages: data,
                loading: false
            })
        };
        let errorFn = function () {
            that.setState({
                loading: false,
            });
        };
        getAPI(SLIDER_IMAGE_API, successFn, errorFn);
    }

    loadBlogPosts = (page = 1) => {
        let that = this;
        this.setState({
            loadingBlog: true,
        });
        let successFn = function (data) {
            that.setState(function (prevState) {
                if (data.current == 1) {
                    return {
                        blogPosts: [...data.results],
                        next: data.next,
                        loadingBlog: false,
                    }
                }

            })
        };
        let errorFn = function () {
            that.setState({
                loadingBlog: false,
            });
        };
        let apiParams = {
            page: page,
            page_size: 6,
        }
        getAPI(BLOG_POSTS_API, successFn, errorFn, apiParams);
    }

    render() {
        const data = [
            {
                title: <Trans i18nKey="Home_Vision_Head"/>,
                des: <div><p>
                    <TranslateContent i18nKey="Home_Vision_Content"/>
                    </p>
                </div>
            },
            {
                title: <Trans i18nKey="Home_Mission_Head"/>,
                des: <div><p>
                    <TranslateContent i18nKey="Home_Mission_Content"/>
                    </p></div>
            },
        ];
        return (
            <div>
                <Spin tip={"Loading..."} spinning={this.state.loading}>
                    <Row className="section home-corousal-section" justify="space-around" align="middle">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showIndicators showArrows  stopOnHover={false} selectedItem={0}>
                                {this.state.carouselImages.map(item => <div key={item.id}>
                                    <img src={makeFileURL(item.silder_image)}
                                         alt={item.title}
                                         style={{width: '100%'}}/>
                                    <p className="legend">{item.title}</p>
                                </div>)}
                            </Carousel>
                        </Col>
                    </Row>
                </Spin>

                <Row type="flex" align="middle" gutter={16} className="section  main-container">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="undraw-wrapper">
                        <UndrawDoctors primaryColor={UNDRAW_PRIMARY_COLOR} height={"400px"}/>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <h1 style={{textAlign: 'center'}}>
                            <Trans i18nKey={"Home_WhoWeAre_Head"}/>
                        </h1>
                            <TranslateContent i18nKey={"Home_WhoWeAre_Content"}/>


                    </Col>
                </Row>
                <div className="dark-bg">
                    <Row className="section mt-2  main-container ">
                        <h1 style={{textAlign: 'center'}}><Trans i18nKey={"Home_Banner_Head"}/> </h1>
                            <TranslateContent i18nKey={"Home_Banner_Content"}/>
                    </Row>
                </div>

                {/*<div className="">*/}
                {/*    <Row className="section mb-2 main-container ">*/}
                {/*        <h1 style={{textAlign: 'center'}} className="mt-2">*/}
                {/*            <Trans i18nKey={"Home_Mission_Banner_Head"}/>*/}
                {/*           </h1>*/}
                {/*        <List*/}
                {/*            itemLayout="horizontal"*/}
                {/*            dataSource={data}*/}
                {/*            renderItem={item => (*/}
                {/*                <List.Item>*/}
                {/*                    <List.Item.Meta*/}
                {/*                        // avatar={<Avatar size="large"*/}
                {/*                        //                 style={{backgroundColor: UNDRAW_PRIMARY_COLOR}}*/}
                {/*                        //                 icon="user"/>}*/}
                {/*                        title={<h2>{item.title}</h2>}*/}
                {/*                        description={<strong>{item.des}</strong>}*/}
                {/*                    />*/}
                {/*                </List.Item>)}*/}
                {/*        />*/}
                {/*    </Row>*/}
                {/*</div>*/}
                <div className="white-bg">
                    <Row className="section  mb-2 main-container ">
                        <h1 style={{textAlign: 'center'}}>
                            <Trans i18nKey="Home_Section_Ayurveda_Head"/>
                            </h1>
                        <p><TranslateContent i18nKey="Home_Section_Ayurveda_Content"/>
                        </p>
                        
                    </Row>
                </div>
                <Row className="section mt-2 mb-2 main-container">
                    <Divider><h2>
                        <Trans i18nKey="Home_Blog_Head"/>
                        </h2></Divider>
                    <Row gutter={24} justify="space-around" align="middle">
                        <Spin spinning={this.state.loadingBlog}>
                            {this.state.blogPosts.map(item => <Col xs={24} sm={24} md={12} lg={12} xl={12} key={item.id}>

                                <BlogArticleShort {...item}/>

                            </Col>)}
                        </Spin>
                    </Row>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: _get(state, 'cart'),
        lang: _get(state, 'global.intlLang'),
    }
};

export default connect(mapStateToProps)(Home)

function BlogArticleShort(post) {
    let des = stripHTML(post.content);
    return <Card
        style={{marginBottom: '15px'}}
        bodyStyle={{height: '100px', overflow: 'hidden'}}
        hoverable
        cover={<img alt={post.title} src={post.featured_image ? makeFileURL(post.featured_image) : BLOG_DEFAULT_THUMB}
                    style={{width: '100%'}}/>}
        actions={[<Link to={'/blog/' + post.slug}>Read More ></Link>]}>
        <Card.Meta title={post.title}/>
    </Card>
}
 function TranslateContent(props){
    const {t} = useTranslation();
    return <div className={"html-content"} dangerouslySetInnerHTML={{__html:t(props.i18nKey)}} />
 }