export const SEO_PAGE_MATCH = {
    '/': 'Home',
    '/aboutus': 'About Us',
    '/video': 'Video',
    '/contact': 'Contact Us',
    '/blog': 'Blog Archive',
    '/event': 'Events Archive',
    '/join/doctor': 'Join Doctor',
    '/join/advisor': 'Join Advisor',
    '/join/others': 'Join Others'
}

export const UNDRAW_PRIMARY_COLOR = '#00642d';
