import React, {Component} from 'react';
import {Layout} from "antd";
import {Route, Switch} from "react-router";
import AppBase from "./app/component/core/AppBase";
import {getAPI} from "./app/utils/common";
import {BLOG_POSTS_API, DISEASE_LIST_API, PAGE_SEO_API} from "./app/constants/api";
import {connect, Provider} from 'react-redux';
import configureStore from "./app/redux/store";
import i18n from './i18n';
import _get from "lodash/get";
import {closeMenuDrawer, openMenuDrawer} from "./app/redux/actions/utilityActions";
import {loadAndChangeLanguage} from "./app/utils/translations";

const persistantStore = configureStore();
const {store, persistor} = persistantStore;
const {Content} = Layout;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SEO: [],
            disease: [],
            diseaseCat: {}
        }
    }

    componentWillMount() {
        this.loadSEOs();
        // this.loadDisease();
        // i18n.changeLanguage(this.props.lang);
        let that = this;
        let succsss = function (){
            that.props.setMyLanguage({
                type: 'INTERNATIONAL_LANGUAGE',
                payload: that.props.lang || 'ENGLISH',
            })
        }
        loadAndChangeLanguage(that.props.lang || 'ENGLISH',succsss);
    }

    loadSEOs() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                SEO: data
            })
        }
        let errorFn = function () {

        }
        getAPI(PAGE_SEO_API, successFn, errorFn);
    }

    // loadDisease() {
    //     let that = this;
    //     let successFn = function (data) {
    //         // console.log(data);
    //         let diseaseCat = {};
    //         data.results.forEach(function (disease) {
    //             if (diseaseCat[disease.disease_type]) {
    //                 diseaseCat[disease.disease_type].push(disease);
    //             } else {
    //                 diseaseCat[disease.disease_type] = [disease]
    //             }
    //         });
    //         that.setState({
    //             disease: data.results,
    //             diseaseCat: diseaseCat
    //         })
    //     }
    //     let errorFn = function () {
    //
    //     }
    //     getAPI(DISEASE_LIST_API, successFn, errorFn);
    // }

    render() {
        return (
            <Layout>
                <Content >
                    <Switch>
                        <Route render={(route) => <AppBase baseData={this.state} {...route} key={this.props.langHash}/>}/>
                    </Switch>
                </Content>
            </Layout>

        );
    }
}

const mapStateToProps = state => {
    return {
        lang: _get(state, 'global.intlLang'),
        langHash: _get(state, 'global.intlLangHashKey')
    }
};

const mapDispatchToProps = dispatch => ({
    closeMenuDrawer,
    openMenuDrawer,
    setMyLanguage: action => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(App)
