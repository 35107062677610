import React, {Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import {Layout, Button, Spin} from "antd";
import {SEO_PAGE_MATCH} from "../../../app.config";
import {Helmet} from "react-helmet";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import SuggestionBox from "./SuggestionBox";
import Home from "../Home";
import {connect} from "react-redux";
import * as _ from "lodash";
import CartList from "../shop/cartList";

const AboutUs = React.lazy(() => import('../AboutUs'));
const Blog = React.lazy(() => import('../blog/Blog'));
const Video = React.lazy(() => import('../video/Video'));
const Event = React.lazy(() => import('../event/Event'));
const Disease = React.lazy(() => import('../Disease'));
const BlogDetails = React.lazy(() => import('../blog/BlogDetails'));
const Contact = React.lazy(() => import('../Contact'));
const EventDetails = React.lazy(() => import( "../event/EventDetails"));
const JoinUs = React.lazy(() => import('../JoinUs'));
const ProductList = React.lazy(() => import('../product/ProductList'));

const {Content} = Layout;

class AppBase extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        let that = this;
        return (<Layout>
                <div style={{
                    position: 'fixed', left: '19px',
                    bottom: '10px', zIndex: '20'
                }}>
                    <Button type="primary" shape="circle" icon="mail" size={"large"} onClick={this.showDrawer}/>
                </div>
                <SEOContent {...findSEOData(this.props.baseData.SEO, this.props.location.pathname)}/>
                <AppHeader {...this.props}/>
                <div className="app-container">
                    <Content style={{minHeight: 'calc(100vh - 50px)'}}>
                        <Switch>
                            <Route exact path='/' render={(route) => (
                                <Home/>)}/>
                            <Route exact path='/aboutus' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><AboutUs/></Suspense>)}/>
                            <Route exact path='/blog/:id'
                                   render={(route) => (
                                       <Suspense
                                           fallback={(
                                               <Spin spinning tip="Loading....">
                                                   <div style={{width: '100vw', height: '100vh'}}/>
                                               </Spin>
                                           )}
                                       ><BlogDetails key={route.match.params.id}{...route} /></Suspense>)}/>
                            <Route exact path='/blog' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><Blog/></Suspense>)}/>
                            <Route exact path='/video' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><Video/></Suspense>)}/>
                            <Route exact path='/event' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><Event/></Suspense>)}/>
                            <Route exact path='/event/:id' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><EventDetails key={route.match.params.id}{...route}/></Suspense>)}/>
                            <Route exact path='/contact' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><Contact/></Suspense>)}/>

                            <Route exact path='/product' render={(route) => (
                                <Suspense
                                    fallback={(
                                        <Spin spinning tip="Loading....">
                                            <div style={{width: '100vw', height: '100vh'}}/>
                                        </Spin>
                                    )}
                                ><ProductList/></Suspense>)}/>

                            <Route exact path={'/cart'} render={(route) => (
                                <Suspense fallback={( <Spin spinning tip="Loading....">
                                    <div style={{width: '100vw', height: '100vh'}}/>
                                </Spin>)}>
                                    <CartList {...route}/>
                                </Suspense>
                            )}/>

                        </Switch>
                    </Content>

                </div>
                <SuggestionBox {...this.state} close={this.onClose}/>
                <AppFooter {...this.props}/>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: _.get(state, 'global.intlLang')
    }
};
export default connect(mapStateToProps)(AppBase)

function SEOContent(tags) {
    if (tags)
        return <Helmet>
            <title>{tags.title}</title>
            <meta charSet="utf-8"/>
            <meta name="description"
                  content={tags.meta_description}/>
            <meta name="keywords"
                  content={tags.keywords}/>
        </Helmet>;
    return null;
}

function findSEOData(props, path) {
    let sentTags = {};
    if (SEO_PAGE_MATCH[path] && props.results) {
        // console.log("props",props, path, SEO_PAGE_MATCH[path]);
        props.results.forEach(function (tag) {
            if (tag.name == SEO_PAGE_MATCH[path]) {
                sentTags = tag
            }
        });
        return sentTags;
    }
    return null;

}
